import React, { Component } from "react";
import { Route, Redirect } from  "react-router-dom";

const isLoggedIn = () => localStorage.getItem("token") !== null;

class PrivateRoute extends Component {
    render() {
        if (isLoggedIn() || process.env.REACT_APP_ENVIRONMENT==="local") {
            return <Route {...this.props}/>
        }
        return <Redirect to="/login" />
    }
}

export default PrivateRoute
